import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Radio } from 'antd';

import LoadingContainer from '../LoadingContainer/LoadingContainer';
import { useAuth } from '../../auth/AuthContext';
import { IQuestion, ISessionAnswers } from '../../interfaces/common';
import { ENDPOINT } from '../../constants/common-app.const';
import api from '../../utils/api.util';
import SVGBack from '../../img/icons/back.svg';
import SVGNext from '../../img/icons/next.svg';

import css from './TestingForm.module.css';

interface ICheckAnswerResponse {
  isCorrect: boolean;
  isFinished: boolean;
  sessionId: string;
  correctAnswerId: string;
}

interface IData {
  questionGroupId: string;
  questionId?: string;
  answerId: string;
  sessionId: string | null;
}

interface IAnswer {
  [questionId: string]: {
    answerId: string;
    accordance: string;
    correctAnswerId: string;
  };
}

const TEXT = {
  QUESTION_ALT: 'Изображение для вопроса',
  ANSWER_ALT: 'Изображение для ответа №',
  FINISH_TEST: 'Завершить тест',
  NEXT_QUESTION: 'Следующий вопрос',
  PREV_QUESTION: 'Предыдущий вопрос',
  CHECK_ANSWER: 'Проверить',
};

const getQuestions = (questionGroupId: string) =>
  api.get(`${ENDPOINT.QUESTIONS}/${questionGroupId}`);

const getSession = (sessionId: string | null) =>
  sessionId && api.get(`${ENDPOINT.SESSIONS}/${sessionId}`);

const formatAnswers = (sessionAnswers: ISessionAnswers[]) =>
  sessionAnswers.length
    ? sessionAnswers.reduce(
        (res: ISessionAnswers, curr: any) =>
          Object.assign(res, {
            [curr.questionId as string]: {
              answerId: curr.answerId,
              accordance: curr.isCorrect ? 'correct' : 'incorrect',
              correctAnswerId: curr.correctAnswerId,
            },
          }),
        {} as ISessionAnswers,
      )
    : {};

const useQuestions = (
  isAuthenticated: boolean | undefined,
  questionGroupId: string,
) => {
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const getData = async () => {
      const response = await getQuestions(questionGroupId);

      setQuestions(response);
      setLoading(false);
    };

    if (isAuthenticated) getData();
  }, [isAuthenticated, questionGroupId]);

  return {
    questions,
    loading,
  };
};

const useSessionData = (
  isAuthenticated: boolean | undefined,
  sessionId: string | null,
) => {
  const [offset, setOffset] = useState<number>(0);
  const [answers, setAnswers] = useState<IAnswer>({});
  const [isFinished, setIsFinished] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      const session = await getSession(sessionId);
      if (session) {
        const { sessionAnswers, isFinished } = session;

        setOffset(sessionAnswers.length - 1);
        setAnswers(formatAnswers(sessionAnswers));
        setIsFinished(isFinished);
      }
    };
    if (isAuthenticated) getData();
  }, [isAuthenticated]);

  return {
    offset,
    isFinished,
    setOffset,
    answers,
    setIsFinished,
    setAnswers,
  };
};

const TestingForm: FC = () => {
  const [selectedAnswerId, setSelectedAnswerId] = useState(null);

  const history = useHistory();
  const location = useLocation();

  const { isAuthenticated } = useAuth();
  const { id: questionGroupId } = useParams<{ id: string }>();

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const sessionId = query.get('sessionId');

  const { offset, isFinished, setIsFinished, setOffset, answers, setAnswers } =
    useSessionData(isAuthenticated, sessionId);
  const { questions, loading } = useQuestions(isAuthenticated, questionGroupId);

  const appendQueryParamsToURL = useCallback(
    (name: string, sessionId: string) => {
      const params = new URLSearchParams(location.search);

      if (params.toString().includes(name)) {
        params.delete(name);
      }

      params.append(name, sessionId);

      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });
    },
    [history, location.pathname, location.search],
  );

  const displayedQuestion = questions[offset];
  const displayQuestionId = displayedQuestion?.id;
  const answerOptions = displayedQuestion?.answers;

  const shuffleArray = (array: any[]) => {
    if (!array?.length) {
      return [];
    }

    return array.reduce(
      (acc, answer, index) => {
        const j = Math.floor(Math.random() * (index + 1));
        const temp = acc[index];
        acc[index] = acc[j];
        acc[j] = temp;

        return acc;
      },
      [...array],
    );
  };

  const sortedAnswers = useMemo(() => {
    return shuffleArray(answerOptions);
  }, [answerOptions]);

  const lastUserAnswer: boolean = Object.keys(answers).length - 1 === offset;

  const onChange = (event: any) => {
    const inputValue = event.target.value;

    setSelectedAnswerId(inputValue);

    setAnswers((current) => ({
      ...current,
      [displayedQuestion?.id]: {
        answerId: inputValue,
        accordance: 'new',
        correctAnswerId: 'id',
      },
    }));
  };

  const onSubmitClick = async () => {
    const data: IData = {
      questionGroupId: questionGroupId,
      questionId: displayedQuestion?.id,
      answerId: answers[displayedQuestion?.id].answerId,
      sessionId: sessionId || null,
    };
    setSelectedAnswerId(null);
    const {
      isCorrect,
      isFinished,
      sessionId: newSessionId,
      correctAnswerId,
    }: ICheckAnswerResponse = await api.post(ENDPOINT.SESSIONS, data);

    setAnswers((current: IAnswer) => ({
      ...current,
      [displayedQuestion?.id as string]: {
        answerId: data.answerId,
        accordance: isCorrect ? 'correct' : 'incorrect',
        correctAnswerId,
      },
    }));

    if (isFinished) {
      setIsFinished(true);
    }

    appendQueryParamsToURL('sessionId', newSessionId);
  };

  const nextQuestion = async () => {
    if (offset + 1 < questions.length) {
      const newOffset = offset + 1;
      setOffset(newOffset);
      setFirstCircleIndex(calculateFirstCircleIndex(newOffset));
    }
  };

  const prevQuestion = () => {
    if (offset > 0) {
      const newOffset = offset - 1;
      setOffset(newOffset);
      setFirstCircleIndex(calculateFirstCircleIndex(newOffset));
    }
  };

  const calculateFirstCircleIndex = (currentQuestionIndex: any) => {
    if (currentQuestionIndex < 15) {
      return 0;
    }
    return currentQuestionIndex - 14;
  };

  const [firstCircleIndex, setFirstCircleIndex] = useState(0);

  const nextPage = () => {
    if (firstCircleIndex + 15 < questions.length) {
      setFirstCircleIndex((prevIndex) => prevIndex + 1);
    }
  };

  const prevPage = () => {
    if (firstCircleIndex > 0) {
      setFirstCircleIndex((prevIndex) => prevIndex - 1);
    }
  };

  const onFinishedTest = () => {
    history.push({
      pathname: `/completed/${sessionId}`,
    });
  };

  const getAnswerColor = (questionId: string): string => {
    const answer = answers[questionId];

    if (!answer || answer.accordance === 'new') {
      return css.unanswered;
    }

    return answer.accordance === 'correct' ? css.correct : css.incorrect;
  };

  const isQuestionHaveAnswer = (questionId: string): boolean => {
    const answer = answers[questionId];
    return answer && answer.accordance !== 'new';
  };

  const handleQuestionChange = (newOffset: number) => {
    setOffset(newOffset);
  };

  const currentAnswer = answers[displayQuestionId];

  return (
    <LoadingContainer isLoading={loading}>
      <div className={css.container}>
        {/*Навигационные Кружочки*/}
        <section className={css.navigation}>
          <button className={css.navButton} onClick={prevPage}>
            <img src={SVGBack} alt='' />
          </button>
          {questions
            .slice(firstCircleIndex, firstCircleIndex + 17)
            .map((question, index) => (
              <label
                key={index}
                className={`${css.radioWrapper} ${
                  firstCircleIndex + index === offset && css.active
                } ${!isQuestionHaveAnswer(question.id) && css.unanswered}`}
              >
                <div
                  className={`${css.status} ${getAnswerColor(question.id)}`}
                />
                <Radio.Button
                  className={css.radio}
                  value={firstCircleIndex + index}
                  checked={firstCircleIndex + index === offset}
                  onChange={() =>
                    handleQuestionChange(firstCircleIndex + index)
                  }
                  disabled={!answers[question.id]}
                />
                {firstCircleIndex + index + 1}
              </label>
            ))}
          <button
            className={css.navButton}
            onClick={nextPage}
            disabled={firstCircleIndex + 15 >= questions.length}
          >
            <img src={SVGNext} alt='' />
          </button>
        </section>
        {/*Навигационные Кружочки*/}

        <div className={css.content}>
          {/*Вопрос*/}
          <div className={css.questionWrapper}>
            <h3>Вопрос:</h3>
            <div className={css.question}>
              <span className={css.questionTitle}>
                {displayedQuestion?.title}
              </span>

              {displayedQuestion?.imageUrl && (
                <img
                  className={css.pic}
                  src={displayedQuestion.imageUrl}
                  alt={TEXT.QUESTION_ALT}
                />
              )}
            </div>
            {currentAnswer?.accordance === 'incorrect' && (
              <div className={css.textFromNPA}>
                <span>Пояснение: </span>
                {displayedQuestion?.description}
              </div>
            )}
          </div>
          {/*Вопрос*/}

          {/*Ответы*/}
          <div className={css.answersWrapper}>
            {sortedAnswers?.map((answer: any | undefined, index: number) => (
              <label
                className={`${css.answer} ${
                  answer?.id === selectedAnswerId ? css.activeAnswer : ''
                } ${
                  currentAnswer?.accordance === 'correct' &&
                  answer?.id === currentAnswer.answerId
                    ? css.correctBorder
                    : currentAnswer?.accordance === 'incorrect' &&
                      answer?.id === currentAnswer.answerId
                    ? css.incorrectBorder
                    : ''
                } ${
                  currentAnswer && currentAnswer.accordance !== 'new'
                    ? css.noHover
                    : ''
                }`}
                key={answer?.id}
              >
                <span className={css.answerId}>{index + 1}</span>
                <div>
                  <input
                    className={css.radio}
                    type='radio'
                    id={answer?.id}
                    name={'answer'}
                    value={answer?.id}
                    onChange={onChange}
                    checked={answer?.id === selectedAnswerId}
                    disabled={
                      currentAnswer && currentAnswer.accordance !== 'new'
                    }
                  />
                  <div className={css.answerContent}>
                    <span
                      className={
                        currentAnswer?.correctAnswerId === answer?.id &&
                        currentAnswer?.accordance === 'incorrect'
                          ? css.correct
                          : currentAnswer?.answerId === answer?.id &&
                            currentAnswer?.accordance === 'incorrect'
                          ? css.incorrect
                          : ''
                      }
                    >
                      {answer?.title}
                    </span>
                    {answer?.imageUrl && (
                      <img
                        className={css.pic}
                        src={answer.imageUrl}
                        alt={`${TEXT.ANSWER_ALT}${sortedAnswers.indexOf(
                          answer,
                        )}`}
                      />
                    )}
                  </div>
                </div>
              </label>
            ))}
          </div>

          {/*Ответы*/}
        </div>

        <div className={css.actions}>
          <div className={css.result}>
            <span className={css.countQuestions}>
              {`${offset + 1}/${questions?.length}`}
            </span>
            {currentAnswer?.accordance === 'correct' && (
              <span
                className={`${css.statusAnswerText} ${css.correctAnswerText}`}
              >
                Ответ правильный
              </span>
            )}
            {currentAnswer?.accordance === 'incorrect' && (
              <span
                className={`${css.statusAnswerText} ${css.incorrectAnswerText}`}
              >
                Ответ неправильный
              </span>
            )}
          </div>

          <div className={css.buttons}>
            {isFinished && lastUserAnswer ? (
              <>
                <Button
                  className={`${css.actionButton} ${css.actionButtonPrev}`}
                  onClick={prevQuestion}
                  children={TEXT.PREV_QUESTION}
                  disabled={offset <= 0}
                />
                <Button
                  className={css.actionButton}
                  onClick={onFinishedTest}
                  children={TEXT.FINISH_TEST}
                />
              </>
            ) : (
              <>
                <Button
                  className={`${css.actionButton} ${css.actionButtonPrev}`}
                  onClick={prevQuestion}
                  children={TEXT.PREV_QUESTION}
                  disabled={offset <= 0}
                />
                <Button
                  className={css.actionButton}
                  onClick={
                    currentAnswer && currentAnswer.accordance !== 'new'
                      ? nextQuestion
                      : onSubmitClick
                  }
                  disabled={!currentAnswer}
                  children={
                    currentAnswer && currentAnswer.accordance !== 'new'
                      ? TEXT.NEXT_QUESTION
                      : TEXT.CHECK_ANSWER
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
};

export default TestingForm;
